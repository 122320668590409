import { Button } from '@wayste/sour-ui';
import { isRouteErrorResponse, useNavigate, useRouteError } from 'react-router-dom';

export default function ErrorBoundary() {
    const error = useRouteError();
    const navigate = useNavigate();

    if (isRouteErrorResponse(error)) {
        return (
            <div className="flex h-screen flex-col items-center justify-center gap-4">
                <h1 className="text-4xl font-bold">Oops!</h1>
                <p className="text-xl">Sorry, an unexpected error has occurred.</p>
                <p className="text-gray-500">
                    {error.status} - {error.statusText}
                </p>
                <Button className="btn-primary px-5 py-2.5" onClick={() => navigate(-1)}>
                    Go Back
                </Button>
            </div>
        );
    }

    return (
        <div className="flex h-screen flex-col items-center justify-center gap-4">
            <h1 className="text-4xl font-bold">Oops!</h1>
            <p className="text-xl">Sorry, an unexpected error has occurred.</p>
            <Button className="btn-primary px-5 py-2.5" onClick={() => navigate(-1)}>
                Go Back
            </Button>
        </div>
    );
}
