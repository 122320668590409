import { Navigate, Outlet } from 'react-router-dom';
import { useOutletContext } from 'react-router-dom';
import { RouterOutletContext } from '../router';

const AuthWrapper = () => {
    const context = useOutletContext<RouterOutletContext>();

    if (!context.userProfile) {
        return <Navigate to="/login" replace />;
    }

    return <Outlet context={context} />;
};

export default AuthWrapper;
