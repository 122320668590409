import { Navigate, Outlet, useOutletContext } from 'react-router-dom';
import { RouterOutletContext } from '../router';

const AppAdminWrapper = () => {
    const context = useOutletContext<RouterOutletContext>();

    if (!context.isAppAdmin) {
        return <Navigate to="/404" replace />;
    }

    return <Outlet context={context} />;
};

export default AppAdminWrapper;
