import React from 'react';
import { Loading } from '@wayste/sour-ui';

const PageLoader = () => {
    return (
        <div className="flex size-full items-center justify-center">
            <Loading size="h-16 w-16" />
        </div>
    );
};

export default PageLoader;
