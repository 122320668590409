import { useEffect, useRef, useState } from 'react';
import { useIsAppAdmin, useWaysteClient } from '@alliance-disposal/client';
import { Hauler, Profile } from '@alliance-disposal/transport-types';
import { SourProvider } from '@wayste/sour-context';
import { SourSearchAppProvider, SourSearchProviderOld } from '@wayste/sour-search';
import * as Sentry from '@sentry/react';
import { Outlet, useNavigate } from 'react-router-dom';
import Loading from './components/Loading';
import InactiveAccountView from './structure/InactiveAccountView';
import InactiveUserView from './structure/InactiveUserView';
import Layout from './structure/Layout';
import { routes } from './utils';

const App = () => {
    const client = useWaysteClient();
    const navigate = useNavigate();
    const { isAdmin } = useIsAppAdmin();
    const [user, setUser] = useState<Profile.ProfileTransport | null>(null);
    const [hauler, setHauler] = useState<Hauler.HaulerTransport | null>(null);
    const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
    const [isAuthLoading, setIsAuthLoading] = useState<boolean>(true);
    const authLoadedBefore = useRef(false);

    const handleInitialSetupAfterAuth = () => {
        client.equipment().wayste.containerType.setEquipmentListInMemory();
    };

    useEffect(() => {
        const authLoading = client.auth().loading.subscribe((loading) => {
            if (authLoadedBefore.current) return; // Skip if auth has loaded before
            if (!loading) {
                authLoadedBefore.current = true;
            }
            setIsAuthLoading(loading);
        });

        const authSubscription = client.auth().currentAuthority.subscribe(async (auth) => {
            if (auth != null) {
                setIsDataLoading(true);
                Sentry.setUser({ id: auth.profileID });
                try {
                    const [haulerData, userData] = await Promise.all([client.hauler().wayste.fetch(), client.profile().fetchProfile()]);
                    setHauler(haulerData);
                    setUser(userData);
                    handleInitialSetupAfterAuth();
                } catch (error) {
                    console.error('Error fetching hauler data:', error);
                } finally {
                    setIsDataLoading(false);
                }
            } else {
                setUser(null);
                setHauler(null);
            }
        });

        return () => {
            authLoading.unsubscribe();
            authSubscription.unsubscribe();
        };
    }, []);

    if (isAuthLoading || isDataLoading) {
        return <Loading />;
    }

    // If the company (hauler) is inactive, show the inactive account view.
    if (user && hauler) {
        if (hauler.active === false) {
            return <InactiveAccountView onSignOut={() => client.auth().signOut()} />;
        }

        if (user.active === false) {
            return <InactiveUserView onSignOut={() => client.auth().signOut()} />;
        }

        return (
            <SourSearchAppProvider
                environmentOptions={{
                    application: 'wayste',
                    apiKey: import.meta.env.VITE_ELASTIC_KEY as string,
                    environment: import.meta.env.VITE_ELASTIC_ENVIRONMENT,
                }}
                globalRestrictTo={hauler.id}
            >
                <SourProvider application="wayste" isAppAdmin={isAdmin}>
                    <SourSearchProviderOld
                        onSeeAllClick={(q) => {
                            navigate(routes.search.list + `?query[query]=${q}&sort[direction]=DESC&sour-search=true`);
                        }}
                    >
                        <Layout userProfile={user} onSignOut={() => client.auth().signOut()} />
                    </SourSearchProviderOld>
                </SourProvider>
            </SourSearchAppProvider>
        );
    }

    return <Outlet context={{ userProfile: user, waysteLite: hauler?.waysteLite ?? false, isAppAdmin: false }} />;
};

export default App;
