import { useCallback, useEffect, useState } from 'react';
import { useCurrentUserProfile, useWaysteClient } from '@alliance-disposal/client';
import { Button } from '@wayste/sour-ui';
import { XMarkIcon } from '@heroicons/react/24/solid';
import Confetti from 'react-confetti';
import { useNavigate } from 'react-router-dom';

export default function AnnouncementBanner() {
    const [isVisible, setIsVisible] = useState(true);
    const [windowDimensions, setWindowDimensions] = useState({ width: 0, height: 0 });
    const { profile } = useCurrentUserProfile();
    const client = useWaysteClient();
    const navigate = useNavigate();

    const announcement = profile?.unseenAnnouncements?.[0]; // Just grab the first one

    const showDismissal = announcement?.type && ['FEATURE_RELEASE', 'GENERAL'].includes(announcement.type);

    const headerStyle = () => {
        switch (announcement?.type) {
            case 'MAINTENANCE':
                return 'bg-[#FBDEBB] text-black';
            case 'FEATURE_RELEASE':
                return 'bg-gradient-to-r from-blue-500 via-purple-400 to-indigo-400 text-white';
            case 'GENERAL':
                return 'bg-gradient-to-r from-blue-400 via-teal-500 to-indigo-500 text-white';
            default:
                return 'bg-gray-600 text-white';
        }
    };

    const emoji = () => {
        switch (announcement?.type) {
            case 'MAINTENANCE':
                return '⚙️';
            case 'FEATURE_RELEASE':
                return '🎉';
            case 'GENERAL':
                return '📢';
            default:
                return;
        }
    };

    const updateWindowDimensions = useCallback(() => {
        setWindowDimensions({ width: window.innerWidth, height: window.innerHeight });
    }, []);

    useEffect(() => {
        updateWindowDimensions();
        window.addEventListener('resize', updateWindowDimensions);

        return () => window.removeEventListener('resize', updateWindowDimensions);
    }, [updateWindowDimensions]);

    const handleDismiss = async () => {
        setIsVisible(false);
        if (announcement?.id) {
            try {
                await client.profile().announcementViewed(announcement.id);
            } catch (error) {
                console.error('Error dismissing announcement:', error);
            }
        }
    };

    const handleCTA = () => {
        if (announcement?.callToActionLink) {
            navigate(announcement.callToActionLink);
        }
    };

    if (!profile || !announcement || !isVisible) return null;

    return (
        <div className={`z-50 ${headerStyle()} p-2`}>
            <div className="flex items-center justify-between text-sm lg:px-16 lg:text-base">
                <div className="flex items-center space-x-4">
                    <span className="hidden text-3xl lg:block" aria-hidden="true">
                        {emoji()}
                    </span>
                    <div className="flex flex-col lg:flex-row lg:gap-2">
                        <p className="font-bold">{announcement.title}</p>
                        <p className="hidden lg:block">{announcement.description}</p>
                    </div>
                </div>
                <div className="flex items-center space-x-8">
                    {announcement.callToActionLink && (
                        <Button className="btn-wayste-flat w-28" onClick={handleCTA}>
                            Check it Out
                        </Button>
                    )}
                    {showDismissal && (
                        <Button onClick={handleDismiss} className="btn-icon-dense hover:text-black" aria-label="Dismiss">
                            <XMarkIcon className="size-6" />
                        </Button>
                    )}
                </div>
            </div>
            {announcement.type === 'FEATURE_RELEASE' && (
                <Confetti
                    className="fixed inset-0 z-40"
                    width={windowDimensions.width}
                    height={windowDimensions.height}
                    numberOfPieces={windowDimensions.width < 768 ? 250 : 500}
                    gravity={0.1}
                    recycle={false}
                />
            )}
        </div>
    );
}
