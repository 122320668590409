import React from 'react';
import { WaysteClient, WaysteProvider } from '@alliance-disposal/client';
import { init as initApm } from '@elastic/apm-rum';
import { Loader, LoaderOptions } from '@googlemaps/js-api-loader';
import * as Sentry from '@sentry/react';
import { createRoot } from 'react-dom/client';
import { RouterProvider, createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import './index.css';
import router from './structure/router';

Sentry.init({
    dsn: 'https://aeebe8e1938644f99731324a77763688@o251766.ingest.sentry.io/6709386',
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.browserProfilingIntegration(),
        Sentry.captureConsoleIntegration({
            levels: ['error', 'warn'],
        }),
        Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect: React.useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
        }),
        Sentry.replayIntegration({
            // Additional SDK configuration goes in here, for example:
            maskAllText: false,
            blockAllMedia: true,
            networkDetailAllowUrls: [/^https:\/\/api\.wayste\.com/],
            networkCaptureBodies: true,
            networkRequestHeaders: ['Content-Type'],
            networkResponseHeaders: ['Content-Type', 'X-Custom-Header'],
        }),
    ],
    release: 'wayste-frontend@' + __APP_VERSION__,
    environment: import.meta.env.MODE,
    tracesSampleRate: 1.0,
    debug: false,
    enabled: import.meta.env.MODE === 'production',
});

initApm({
    // Set required service name (allowed characters: a-z, A-Z, 0-9, -, _, and space)
    serviceName: __APP_NAME__.replace('@wayste/', ''),

    // Set custom APM Server URL (default: http://localhost:8200)
    serverUrl: 'https://1c478fe7e83a416987ea30a190f8d3a1.apm.us-east-1.aws.cloud.es.io:443',

    // Set service version (required for sourcemap feature)
    serviceVersion: __APP_VERSION__,

    // Set Environment
    environment: import.meta.env.MODE === 'production' ? 'production' : 'staging',

    // Set distributed tracing origin for APM Server
    distributedTracingOrigins: import.meta.env.MODE === 'production' ? ['https://api.wayste.com'] : ['https://api-staging.wayste.com'],

    // Set global labels
    logLevel: import.meta.env.MODE === 'production' ? 'warn' : 'warn',

    breakdownMetrics: true,
});

const waysteClient = new WaysteClient({
    cognito: {
        UserPoolId: import.meta.env.VITE_COGNITO_USER_POOL_ID,
        ClientId: import.meta.env.VITE_COGNITO_CLIENT_ID,
        endpoint: import.meta.env.VITE_COGNITO_ENDPOINT,
    },
    API: {
        gqlEndpoint: import.meta.env.VITE_GQL_URL,
        baseApiUrl: import.meta.env.VITE_BASE_API_URL,
        webSocketUrl: import.meta.env.VITE_API_WEBSOCKET_URL,
    },
    settings: {
        defaultApp: 'wayste',
        defaultRole: 'HAULER_ADMIN',
    },
});

const googleLibraries: LoaderOptions['libraries'] = ['places', 'core', 'geocoding'];

const loader = new Loader({
    apiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
    version: 'weekly',
    libraries: googleLibraries,
    id: 'google-maps-script',
});

googleLibraries.map((library) => loader.importLibrary(library).catch(console.error));

createRoot(document.getElementById('root') as HTMLElement).render(
    <React.StrictMode>
        <WaysteProvider client={waysteClient}>
            <RouterProvider router={router} />
        </WaysteProvider>
    </React.StrictMode>,
);
