import { Outlet, useOutletContext } from 'react-router-dom';
import WaysteLiteDisabled from '../../pages/WaysteLiteDisabled';
import { RouterOutletContext } from '../router';

const WaysteStandardWrapper = () => {
    const context = useOutletContext<RouterOutletContext>();

    if (context.waysteLite) {
        return <WaysteLiteDisabled />;
    }

    return <Outlet context={context} />;
};

export default WaysteStandardWrapper;
