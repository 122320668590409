import { Navigate, Outlet } from 'react-router-dom';
import { useOutletContext } from 'react-router-dom';
import { RouterOutletContext } from '../router';

const PublicOnlyWrapper = () => {
    const context = useOutletContext<RouterOutletContext>();

    if (context.userProfile) {
        return <Navigate to="/orders" replace />;
    }

    return <Outlet context={context} />;
};

export default PublicOnlyWrapper;
