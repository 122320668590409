import { WaysteFullLogo } from '@wayste/sour-icons';

type Props = {
    onSignOut: () => void;
};

const InactiveAccountView = ({ onSignOut }: Props) => (
    <div className="flex min-h-screen flex-1 grow flex-col bg-[#F0F2F4]">
        <div className="bg-sourgum-greyblue-900 flex items-center justify-center p-5 text-white">
            <a href="https://www.wayste.com" style={{ textDecoration: 'none', color: '#FFFFFF' }}>
                <WaysteFullLogo style={{ fontSize: 200, height: 50 }} />
            </a>
        </div>
        <div className="flex flex-1 flex-col items-center justify-center px-6 text-center">
            <p className="text-lg font-bold">This Account is Not Active. To Re-Activate Your Account Please Email support@wayste.com</p>
            <button className="btn-primary mt-5" onClick={() => onSignOut()}>
                Sign Out
            </button>
        </div>
    </div>
);

export default InactiveAccountView;
