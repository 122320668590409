import React from 'react';
import { Loading as SourLoading } from '@wayste/sour-ui';

const Loading = () => {
    return (
        <div className="container mx-auto p-6">
            <SourLoading size="h-12 w-12" className="absolute inset-0 m-auto" />
        </div>
    );
};

export default Loading;
