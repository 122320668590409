import routes from '../utils/routes';

const WaysteLiteDisabled = () => {
    return (
        <div className="shadow-dark m-2 flex h-screen flex-col items-center justify-center rounded bg-white p-2">
            <h1 className="text-3xl font-light">Wayste Standard account not yet enabled</h1>
            <div className="mt-6 max-w-[50%] text-center text-xl font-light">
                <p className="mb-6">
                    Wayste Standard provides a robust dispatching, inventory management, pricing, and invoicing system for all of your
                    orders, not just the ones sent from Partner Portals.
                </p>
                <p className="mb-6 font-bold">
                    To upgrade to Wayste Standard,{' '}
                    <a href={routes.scheduleWaysteDemo} className="text-wayste-blue-400 underline" target="_blank">
                        schedule a demo
                    </a>{' '}
                    today!
                </p>
                <p>
                    A Wayste representative will walk you through an in-depth product demo, and answer any questions you have. Setup is fast
                    and easy, Wayste provides free setup support to get your wheels turning in as little as 24 hours.
                </p>
            </div>
        </div>
    );
};

export default WaysteLiteDisabled;
