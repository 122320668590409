import { useCurrentAccount } from '@alliance-disposal/client';
import { DisposalSite } from '@alliance-disposal/transport-types';
import { AddressSubForm } from '@wayste/sour-components';
import { TextField } from '@wayste/sour-ui';
import { Controller, FormProvider, useForm } from 'react-hook-form';

interface DisposalSiteFormProps {
    onSubmit: (data: DisposalSite.DisposalSiteCreateInput | DisposalSite.DisposalSiteUpdateInput) => void;
    site?: DisposalSite.DisposalSiteTransport;
}

interface FormProps {
    name: string;
    location: {
        address: {
            addressLine1: string;
            addressLine2: string | '';
            city: string;
            state: string;
            zip: string;
            country: string;
        };
        coordinates: {
            lat: number;
            lng: number;
        };
    };
    id?: string;
    active?: boolean;
}

export const DisposalSiteForm = ({ onSubmit, site }: DisposalSiteFormProps) => {
    const { hauler } = useCurrentAccount();

    const onFormSubmit = (values: FormProps) => {
        const address = values.location.address;
        const coordinates = values.location.coordinates;
        const cleanData: DisposalSite.DisposalSiteCreateInput | DisposalSite.DisposalSiteUpdateInput = {
            name: values.name,
            address: {
                addressLine1: address.addressLine1,
                addressLine2: address.addressLine2,
                city: address.city,
                state: address.state,
                zip: address.zip,
                country: address.country,
            },
            coordinates: {
                lat: coordinates.lat,
                lng: coordinates.lng,
            },
        };
        onSubmit(cleanData);
    };

    const methods = useForm<FormProps>({
        mode: 'all',
        defaultValues: site
            ? {
                  id: site.id,
                  name: site.name,
                  location: {
                      address: {
                          addressLine1: site.address.addressLine1,
                          addressLine2: site.address.addressLine2 || '',
                          city: site.address.city,
                          state: site.address.state,
                          zip: site.address.zip,
                          country: site.address.country,
                      },
                      coordinates: {
                          lat: site.coordinates.lat,
                          lng: site.coordinates.lng,
                      },
                  },
                  active: site.active,
              }
            : {
                  name: '',
                  location: {
                      address: {
                          addressLine1: '',
                          addressLine2: '',
                          city: '',
                          state: '',
                          zip: '',
                      },
                      coordinates: {
                          lat: 0,
                          lng: 0,
                      },
                  },
              },
    });

    const {
        control,
        formState: { errors },
        handleSubmit,
    } = methods;

    return (
        <FormProvider {...methods}>
            <form className="flex flex-col gap-6" id="disposal-site-form" autoComplete="off" onSubmit={handleSubmit(onFormSubmit)}>
                <div>
                    <Controller
                        control={control}
                        name="name"
                        rules={{
                            required: {
                                value: true,
                                message: 'A name is required',
                            },
                        }}
                        render={({ field }) => (
                            <TextField
                                label="Disposal Site Name"
                                error={errors.name}
                                required
                                inputProps={{
                                    ...field,
                                    autoComplete: 'no',
                                }}
                            />
                        )}
                    />
                </div>
                <div>
                    <AddressSubForm
                        required
                        fieldName="location"
                        type="location"
                        apiKey={import.meta.env.VITE_GOOGLE_MAPS_API_KEY}
                        restrictToCountry={hauler?.billingAddress.country}
                    />
                </div>
            </form>
        </FormProvider>
    );
};
