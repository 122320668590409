import { useCurrentAccount } from '@alliance-disposal/client';
import { SourgumLeafGreenCircle, WaysteFullLogo } from '@wayste/sour-icons';

const NavBarBranding = () => {
    const { hauler } = useCurrentAccount();

    if (hauler.waysteLite && hauler.isSourgumPartner) {
        return (
            <div className="flex h-full items-center gap-2">
                <SourgumLeafGreenCircle className="h-6 w-auto" />
                <span className="text-sourgum-green-500 whitespace-nowrap text-sm font-bold leading-tight tracking-tight">
                    <span className="xs:inline-block hidden">Sourgum</span> Partner Portal
                </span>
                <div className="bg-sourgum-green-500 my-0.5 h-5 w-[0.3px]" />
                <span className="text-wayste-blue-900 h-[22px] whitespace-nowrap text-xs font-normal italic leading-[1.42857] tracking-[0.21px] sm:text-sm">
                    <span className="xs:inline-block hidden">powered</span> by
                </span>
                <WaysteFullLogo className="fill-wayste-blue-900 h-4 w-auto" />
            </div>
        );
    }

    return <WaysteFullLogo className="fill-wayste-blue-900 h-6 w-auto" />;
};

export default NavBarBranding;
