import { WaysteFullLogo } from '@wayste/sour-icons';

type Props = {
    onSignOut: () => void;
};

const InactiveUserView = ({ onSignOut }: Props) => (
    <div
        style={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            flex: 1,
            minHeight: '100vh',
            backgroundColor: '#F0F2F4',
        }}
    >
        <div className="bg-sourgum-greyblue-900 flex items-center justify-center p-5 text-white">
            <a href="https://www.wayste.com" style={{ textDecoration: 'none', color: '#FFFFFF' }}>
                <WaysteFullLogo style={{ fontSize: 200, height: 50 }} />
            </a>
        </div>
        <div
            style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                paddingRight: 25,
                paddingLeft: 25,
            }}
        >
            <h5 className="text-xl font-bold">
                Your User has been made inactive by your account manager. If you believe this is a mistake, please contact your account
                manger.
            </h5>
            <button className="btn-primary mt-5" onClick={() => onSignOut()}>
                Sign Out
            </button>
        </div>
    </div>
);

export default InactiveUserView;
