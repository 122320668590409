import { useState } from 'react';
import { FeatureFlag } from '@alliance-disposal/client';
import { TriangleDownIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

export const classItemBlock =
    'text-gray-600 bg-slate-900 bg-opacity-5 pl-8 pr-4 py-2 text-sm cursor-pointer hover:bg-opacity-10 h-10 flex flex-row items-center transition-all w-full justify-start gap-1 whitespace-pre-line';

const ExpandingBlock = ({
    heading,
    routeList,
    initiallyExpanded = false,
    onNavigate,
    featureFlags = [],
}: {
    heading: string;
    routeList: { text: string; path: string; featureFlag?: FeatureFlag | boolean }[];
    initiallyExpanded?: boolean;
    onNavigate?: () => void;
    featureFlags?: FeatureFlag[];
}) => {
    const navigate = useNavigate();

    const [expanded, setExpanded] = useState(() => {
        const storedExpandedState = localStorage.getItem(heading + '-expanded');
        const initialExpandedState = storedExpandedState != null ? JSON.parse(storedExpandedState) : initiallyExpanded;
        return initialExpandedState;
    });

    const routes = routeList.filter((route) => {
        if (typeof route.featureFlag === 'boolean') {
            return route.featureFlag;
        }

        if (!route.featureFlag) {
            return true;
        }

        return featureFlags.includes(route.featureFlag);
    });

    if (routes.length === 0) {
        return null;
    }

    const handleExpandedStateChange = (newExpandedState: boolean) => {
        localStorage.setItem(heading + '-expanded', JSON.stringify(newExpandedState));
        setExpanded(newExpandedState);
    };

    return (
        <div className="flex flex-col gap-y-[0.0625rem]">
            <div
                className="hover:bg-sourgum-greyblue-900 flex h-10 cursor-pointer flex-row items-center justify-between px-4 py-2 text-xs font-semibold capitalize text-[#656567] hover:bg-opacity-[0.06]"
                onClick={() => handleExpandedStateChange(!expanded)}
            >
                <span className="whitespace-pre-line uppercase">{heading}</span>
                <TriangleDownIcon
                    className={twMerge(
                        `h-4 w-4 flex-shrink-0 rotate-0 transform transition-transform duration-500 ease-out ${
                            expanded ? '' : 'rotate-90'
                        }`,
                    )}
                />
            </div>
            <div
                className={twMerge(
                    'transition-max-h flex flex-col gap-y-[0.0625rem] overflow-hidden duration-500 ease-in-out',
                    expanded ? 'max-h-screen' : 'max-h-0',
                )}
            >
                {routes.map((route) => (
                    <div
                        className={classItemBlock}
                        key={routeList.indexOf(route)}
                        onClick={() => {
                            onNavigate?.();
                            navigate(route.path);
                        }}
                    >
                        <span className="whitespace-pre-line text-sm font-normal leading-normal tracking-tight text-gray-600">
                            {route.text}
                        </span>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ExpandingBlock;
