import React, { createContext, useEffect, useMemo, useState } from 'react';
import { useCurrentAuthorityToken, useWaysteClient } from '@alliance-disposal/client';
import { Hauler, Order, S3ItemReference } from '@alliance-disposal/transport-types';
import { useSourContext } from '@wayste/sour-context';
import * as Sentry from '@sentry/react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

type S3Context = {
    fetchImage: (key: string) => Promise<{ status: string; url: string }>;
    uploadOrderImage: (file: File, order: any, account: any) => Promise<{ bucket: string; key: string; region: string }>;
    deleteS3FileFromFolder: (key: string) => Promise<boolean>;
    uploadDriverTaskImage: (
        blob: Blob,
        taskId: string,
        account: Hauler.HaulerTransport,
    ) => Promise<{ bucket: string; key: string; region: string }>;
    uploadImageToS3: (
        files: string[],
        entityID: string,
        account: Hauler.HaulerTransport,
        otherS3Fields?: {
            type?: string;
            note?: string;
            visibleToCustomer?: boolean;
        },
    ) => Promise<{ bucket: string; key: string; region: string }[]>;
    uploadHaulerLogo: (file: File, haulerID: string) => Promise<{ bucket: string; key: string; region: string; type: string }>;
    uploadHaulerLogoPublic: (file: File, haulerID: string) => Promise<{ bucket: string; key: string; region: string; type: string }>;
    deleteImageFromOrder: (order: Order.OrderTransport, image: S3ItemReference) => Promise<boolean>;
    loading: boolean;
    imageAPI: {
        fetch: string;
        upload: string;
        uploadPublicLogo: string;
        delete: string;
        deleteFromOrder: string;
    };
};

/**
 * @deprecated use the wayste client Upload service instead
 * @type {import('react').Context<TGeneralContext>}
 *  */
export const S3Context = createContext<S3Context>({} as S3Context);

export const S3Provider = ({ children }: { children: React.ReactNode }) => {
    const client = useWaysteClient();
    const { setShowToast } = useSourContext();
    let authToken = useCurrentAuthorityToken();
    const [loading, setLoading] = useState(false);

    const imageAPI = {
        fetch: import.meta.env.VITE_BASE_API_URL + '/image/wayste/fetch',
        upload: import.meta.env.VITE_BASE_API_URL + '/image/wayste/upload',
        uploadPublicLogo: import.meta.env.VITE_BASE_API_URL + '/image/wayste/upload/logo',
        delete: import.meta.env.VITE_BASE_API_URL + '/image/wayste/delete',
        deleteFromOrder: import.meta.env.VITE_BASE_API_URL + '/order/',
    };
    // const s3 = useRef(null);

    useEffect(() => {
        const constAuthSub = client.auth().currentAuthority.subscribe((token) => {
            if (token) {
                authToken = token.token;
            }
        });

        return () => {
            constAuthSub.unsubscribe();
        };
    }, [client]);

    /**
     * Fetches an image from S3
     * @param key key of the image
     * @returns image url
     */
    const fetchImage = async (key: string) => {
        try {
            const res = await axios.post(imageAPI.fetch, { path: key }, { headers: { Authorization: `Bearer ${authToken}` } });
            return { status: 'success', url: String(res.data) };
        } catch (error) {
            Sentry.captureException(error);
            throw error;
        }
    };

    /**
     * Uploads an image to S3
     * @param {File} file file object
     * @param {Order} order order it is attached to
     * @returns { bucket, key, region, type }
     */
    const uploadOrderImage = async (file: File, order: Order.OrderTransport, account: Hauler.HaulerTransport) => {
        try {
            setLoading(true);
            const ext = file.name.split('.').pop();
            const key = `${account.id}/${order.id}/${uuidv4()}.${ext}`;

            const formData = new FormData();
            formData.append('path', key);
            formData.append('file', file);

            const res = await axios.post(imageAPI.upload, formData, {
                headers: { Authorization: `Bearer ${authToken}`, 'Content-Type': 'multipart/form-data' },
            });

            return {
                bucket: res.data.Bucket,
                key: res.data.Key,
                region: 'us-east-1',
            };
        } catch (error) {
            Sentry.captureException(error);
            throw error;
        }
    };

    /**
     * Uploads an image to S3 (Hauler Logo)
     * @param {File} file file object
     * @param {Order} order order it is attached to
     * @returns { bucket, key, region, type }
     */
    const uploadHaulerLogo = async (file: File, haulerID: string) => {
        try {
            const ext = file.name.split('.').pop();
            const key = `${haulerID}/logo/${uuidv4()}.${ext}`;

            const formData = new FormData();
            formData.append('path', key);
            formData.append('file', file);

            const res = await axios.post(imageAPI.upload, formData, {
                headers: { Authorization: `Bearer ${authToken}`, 'Content-Type': 'multipart/form-data' },
            });

            return {
                bucket: res.data.Bucket,
                key: res.data.Key,
                region: 'us-east-1',
                type: 'hauler-logo',
            };
        } catch (error) {
            Sentry.captureException(error);
            throw error;
        }
    };

    /**
     * Uploads an image to S3 (Hauler Logo)
     * @param {File} file file object
     * @param {Order} order order it is attached to
     * @returns { bucket, key, region, type }
     */
    const uploadHaulerLogoPublic = async (file: File, haulerID: string) => {
        try {
            const ext = file.name.split('.').pop();
            const key = `${haulerID}/logo/${uuidv4()}.${ext}`;

            const formData = new FormData();
            formData.append('path', key);
            formData.append('file', file);

            const res = await axios.post(imageAPI.uploadPublicLogo, formData, {
                headers: { Authorization: `Bearer ${authToken}`, 'Content-Type': 'multipart/form-data' },
            });

            return {
                bucket: res.data.Bucket,
                key: res.data.Key,
                region: 'us-east-1',
                type: 'hauler-logo',
            };
        } catch (error) {
            Sentry.captureException(error);
            throw error;
        }
    };

    /**
     * Uploads an image to S3 (Driver Task)
     * @param {Blob} file file object
     * @param {string} taskId task id
     * @returns { bucket, key, region, type }
     *  */
    const uploadDriverTaskImage = async (blob: Blob, taskId: string, account: Hauler.HaulerTransport) => {
        setLoading(true);

        const imgExtension = blob.type.split('/')[1];
        const uuid = uuidv4();
        const key = `${account.id}/${taskId}/${uuid}.${imgExtension}`;
        const file = new File([blob], `${uuid}.${imgExtension}`, { type: blob.type });

        const formData = new FormData();
        formData.append('path', key);
        formData.append('file', file);

        const res = await axios.post(imageAPI.upload, formData, {
            headers: { Authorization: `Bearer ${authToken}`, 'Content-Type': 'multipart/form-data' },
        });

        setLoading(false);
        return {
            bucket: res.data.Bucket,
            key: res.data.Key,
            region: 'us-east-1',
        };
    };

    const uploadImageToS3 = async (
        files: string[],
        entityID: string,
        account: Hauler.HaulerTransport,
        otherS3Fields: {
            type?: string;
            note?: string;
            visibleToCustomer?: boolean;
        } = {},
    ) => {
        if (!account) throw new Error('Account not found');
        const fetchBlobReqs = [];
        for (const image of files) {
            const getBlobReq = fetch(image);
            fetchBlobReqs.push(getBlobReq);
        }
        const blobResponses = await Promise.all(fetchBlobReqs);
        const blobsReqs = [];
        for (const response of blobResponses) {
            const blob = response.blob();
            blobsReqs.push(blob);
        }
        const blobs = await Promise.all(blobsReqs);
        const s3Reqs = [];

        for (const blob of blobs) {
            // if (!blob.type.includes('image')) continue;

            const imgExtension = blob.type.split('/')[1];
            const uuid = uuidv4();
            const key = `${account.id}/${entityID}/${uuid}.${imgExtension}`;
            const file = new File([blob], `${uuid}.${imgExtension}`, { type: blob.type });

            const formData = new FormData();
            formData.append('path', key);
            formData.append('file', file);
            try {
                const res = await axios.post(imageAPI.upload, formData, {
                    headers: { Authorization: `Bearer ${authToken}`, 'Content-Type': 'multipart/form-data' },
                });

                const uploadS3: S3ItemReference = {
                    bucket: res.data.Bucket,
                    key: res.data.Key,
                    region: 'us-east-1',
                    timestamp: new Date().toISOString(),
                    ...otherS3Fields,
                };

                s3Reqs.push(uploadS3);
            } catch (error) {
                setShowToast({
                    message: 'Error uploading image. Please try again.',
                    severity: 'error',
                });
            }
        }
        return s3Reqs;
    };

    /**
     * Deletes an image from S3 by removing it from order images array
     * @param {string} key key of the image
     * @returns {boolean} success
     */
    const deleteS3FileFromFolder = async (key: string) => {
        try {
            const res = await axios.delete(imageAPI.delete, {
                data: { path: key },
                headers: { Authorization: `Bearer ${authToken}` },
            });

            return Boolean(res.data);
        } catch (error) {
            Sentry.captureException(error);
            throw error;
        }
    };

    /**
     * Deletes an image from S3 by removing it from order images array
     * @param {S3ItemReference} image S3ItemReference
     * @param {Order} order order it is attached to
     * @returns {boolean} success
     */
    const deleteImageFromOrder = async (order: Order.OrderTransport, image: S3ItemReference) => {
        try {
            const res = await axios.delete(import.meta.env.VITE_BASE_API_URL + `/order/${order.id}/remove-image`, {
                data: image,
                headers: { Authorization: `Bearer ${authToken}` },
            });

            return Boolean(res.data);
        } catch (error) {
            Sentry.captureException(error);
            throw error;
        }
    };

    const values = useMemo(
        () =>
            ({
                fetchImage,
                uploadOrderImage,
                deleteS3FileFromFolder,
                deleteImageFromOrder,
                uploadDriverTaskImage,
                uploadImageToS3,
                uploadHaulerLogo,
                uploadHaulerLogoPublic,
                loading,
                imageAPI,
            }) as S3Context,
        [loading, imageAPI],
    );

    return <S3Context.Provider value={values}>{children}</S3Context.Provider>;
};
