import { useState } from 'react';
import { useCurrentAccount, useIsAppAdmin, useWaysteClient } from '@alliance-disposal/client';
import { DisposalSite, Hauler } from '@alliance-disposal/transport-types';
import { useSourContext } from '@wayste/sour-context';
import { Button, Dialog, Loading, Stepper } from '@wayste/sour-ui';
import { useNavigate } from 'react-router-dom';
import { DisposalSiteForm } from '../../pages/account-settings/components/disposal-sites';
import { MaterialForm } from '../../pages/account-settings/components/material-settings';
import routes from '../../utils/routes';
import AccountForm from '../AccountForm';

interface Props {
    open: boolean;
    onClose: () => void;
}

const NewAccountDialog = ({ open, onClose }: Props) => {
    const client = useWaysteClient();
    const { hauler } = useCurrentAccount();
    const { isAdmin } = useIsAppAdmin();
    const { setShowAlert, setShowToast } = useSourContext();
    const navigate = useNavigate();
    const [activeStep, setActiveStep] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [formID, setFormID] = useState('account-settings-form');

    const handleOnClose = (finished?: boolean) => {
        if (isAdmin || finished) {
            onClose?.();
        } else {
            setShowToast({
                severity: 'info',
                message: 'You must complete the account setup to continue.',
            });
        }
        return;
    };

    const handleBackClick = () => {
        if (activeStep !== 0) {
            const newStep = activeStep - 1;
            switch (newStep) {
                case 0:
                    setFormID('account-settings-form');
                    break;
                case 1:
                    setFormID('disposal-site-form');
                    break;
                default:
                    setFormID('account-settings-form');
                    break;
            }
            setActiveStep(newStep);
        }
    };

    const handleAccountSubmit = async (data: Partial<Hauler.HaulerTransport>) => {
        setIsLoading(true);
        try {
            await client.hauler().wayste.update({ ...data });

            const disposalSitesResponse = await client.disposalSite().wayste.query();
            // check and skp to 2 if disposal site exists
            setFormID(disposalSitesResponse.length > 0 ? 'account-material-select-form' : 'disposal-site-form');
            setActiveStep(disposalSitesResponse.length > 0 ? 2 : 1);
        } catch (error) {
            setShowAlert({
                message: 'An error occurred, please try again.',
                severity: 'error',
            });
            setIsLoading(false);
            return;
        } finally {
            setIsLoading(false);
        }
    };

    const handleDisposalSiteSubmit = async (data: DisposalSite.DisposalSiteCreateInput) => {
        setIsLoading(true);
        const dataCopy = JSON.parse(JSON.stringify(data));
        try {
            await client.disposalSite().wayste.create(dataCopy);
            setIsLoading(false);
            setFormID('account-material-select-form');
            setActiveStep(2);
        } catch (error) {
            console.warn('NEwAccountDialog createDisposalSite Error: ', error);
            setShowAlert({
                message: 'An error occurred creating the disposal site. Try reloading the browser and trying again.',
                severity: 'error',
            });
            setIsLoading(false);
        }
    };

    const handleMaterialSubmit = async (data: any) => {
        setIsLoading(true);
        try {
            if (!hauler) throw new Error('Account not found');
            await client.hauler().wayste.update({ id: hauler.id, materials: data });
            if (hauler.waysteLite) {
                navigate(routes.settings.listUsers);
                setShowAlert({
                    message: 'Congratulations your account is setup to use! Now lets invite your first teammate.',
                    severity: 'success',
                });
            } else {
                navigate(routes.equipment.containers);
                setShowAlert({
                    message: 'Congratulations your account is setup to use! Now lets start adding your equipment.',
                    severity: 'success',
                });
            }
            handleOnClose(true);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            setShowAlert({
                message: 'An error occurred, please try again.',
                severity: 'error',
            });
        }
    };

    const renderView = () => {
        switch (activeStep) {
            case 0:
                return <AccountForm onSubmit={handleAccountSubmit} haulerAccount={hauler} hideSubmit />;
            case 1:
                return (
                    <div>
                        <div className="font-semibold">Add your first disposal site.</div>
                        <div className="my-2 flex items-center">
                            You can always additional disposal sites by going to the Settings Page - Account Settings - Add Disposal Site
                        </div>
                        <DisposalSiteForm
                            onSubmit={(values) => handleDisposalSiteSubmit(values as DisposalSite.DisposalSiteCreateInput)}
                            site={undefined}
                        />
                    </div>
                );
            case 2:
                return (
                    <div>
                        <div>
                            Select the materials that you service. The selected materials will be the only materials available to create
                            pricing and other important info with.
                        </div>
                        {hauler ? <MaterialForm onSubmit={handleMaterialSubmit} account={hauler} /> : <Loading />}
                    </div>
                );
            default:
                return <div>Error missing step</div>;
        }
    };

    return (
        <Dialog
            open={open}
            className="min-h-[70dvh] w-full max-w-screen-lg"
            onClose={handleOnClose}
            styledTitle="New Account Setup"
            showX={isAdmin}
            dialogBodyClassName="flex flex-1 w-full"
        >
            <div className="flex w-full flex-col justify-between gap-2">
                <div className="mx-auto w-full max-w-4xl">
                    <div className="mb-6">
                        <Stepper activeStep={activeStep} steps={['Account Info', 'Disposal Site', 'Materials']} />
                    </div>
                    {renderView()}
                </div>
                <div className="border-t py-4">
                    <div className="flex items-end justify-end gap-4">
                        <Button
                            className="btn-secondary-dark-grey"
                            onClick={handleBackClick}
                            disabled={activeStep === 0}
                            loading={isLoading}
                        >
                            Back
                        </Button>
                        <Button type="submit" className="btn-primary" form={formID} loading={isLoading}>
                            Next
                        </Button>
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

export default NewAccountDialog;
