import { FeatureFlag, useCurrentAccount } from '@alliance-disposal/client';
import { twMerge } from 'tailwind-merge';
import routes from '../../utils/routes';
import ExpandingBlock from './components/expanding-block';

/**
 * Side navigation bar on desktop ( > 1024px) displays
 */
const Toolbar = ({
    desktopToolBarOpen: forceToolbarOpen,
    isDesktop,
    toggleOpen,
    featureFlags,
}: {
    isDesktop: boolean;
    desktopToolBarOpen: boolean;
    toggleOpen?: () => void;
    featureFlags?: FeatureFlag[];
}) => {
    const { hauler } = useCurrentAccount();
    const desktopToolBarOpen = forceToolbarOpen;

    const navigateSideEffect = () => {
        if (!isDesktop) {
            toggleOpen?.();
        }
    };

    return (
        <nav
            className={`${
                desktopToolBarOpen ? 'w-full md:w-[10.25rem]' : 'w-0 md:mr-4 md:w-2'
            } group/nav relative flex flex-none flex-col overflow-visible transition-all duration-200 ease-in`}
        >
            {/* collapse button */}
            <div
                className={twMerge(
                    'absolute bottom-0 right-0 top-0 hidden bg-slate-200 transition-all duration-200 ease-in md:block',
                    desktopToolBarOpen ? 'ml-2 w-0' : '-right-2 w-0.5',
                )}
            >
                <div
                    className={twMerge(
                        'hover:bg-primary-400 group absolute right-1/2 top-20 z-50 hidden size-6 translate-x-1/2 cursor-pointer items-center justify-center rounded-full bg-white shadow-[0_0_0_1px_rgba(9,30,66,0.08),0_2px_4px_1px_rgba(9,30,66,0.08)] transition-all duration-200 ease-in-out hover:size-7 group-hover/nav:opacity-100 md:flex',
                        forceToolbarOpen ? '-rotate-90 md:opacity-0' : 'rotate-90',
                    )}
                    onClick={toggleOpen}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="size-6 transform text-zinc-700 transition-all duration-200 ease-in-out hover:scale-110 group-hover:text-white"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 15l7-7 7 7" />
                    </svg>
                </div>
            </div>

            <div
                className={`bg-main-sidebar w-full flex-1 flex-col gap-y-8 overflow-hidden overflow-y-auto whitespace-nowrap py-8 ${
                    desktopToolBarOpen ? 'flex' : 'hidden'
                }`}
            >
                {/* <div
                    className={classItemBlock}
                    onClick={() => {
                        navigateSideEffect();
                        // history(routes.leads.create);
                    }}
                >
                    Create Lead
                </div> */}

                <ExpandingBlock
                    initiallyExpanded={true}
                    onNavigate={navigateSideEffect}
                    heading="Roll Off"
                    featureFlags={featureFlags}
                    routeList={[
                        {
                            text: 'Orders',
                            path: routes.orders.list,
                        },
                        {
                            text: 'Schedules',
                            path: routes.roScheduling.list,
                            featureFlag: 'wayste-standard',
                        },
                    ]}
                />

                <ExpandingBlock
                    initiallyExpanded={true}
                    onNavigate={navigateSideEffect}
                    featureFlags={featureFlags}
                    heading="Recurring"
                    routeList={[
                        {
                            text: 'Dashboard',
                            path: routes.dispatching.dashboard,
                            featureFlag: 'wayste-commercial',
                        },
                        {
                            text: 'Subscriptions',
                            path: routes.subscriptions.list,
                            featureFlag: 'wayste-commercial',
                        },
                        {
                            text: 'Routes',
                            path: routes.dispatching.commercialRoutes.list,
                            featureFlag: 'wayste-commercial',
                        },
                        {
                            text: 'Driver Schedules',
                            path: routes.dispatching.schedule,
                            featureFlag: 'wayste-commercial',
                        },
                        {
                            text: 'Container Delivery',
                            path: routes.dispatching.containerDelivery,
                            featureFlag: 'wayste-commercial',
                        },
                        {
                            text: 'Learn More',
                            path: routes.commercialPreview,
                            featureFlag: !featureFlags?.includes('wayste-commercial'),
                        },
                    ]}
                />

                <ExpandingBlock
                    initiallyExpanded={true}
                    onNavigate={navigateSideEffect}
                    heading="Operations"
                    featureFlags={featureFlags}
                    routeList={[
                        {
                            text: 'Containers',
                            path: routes.equipment.containers,
                            featureFlag: 'wayste-standard',
                        },
                        {
                            text: 'Container Map',
                            path: routes.equipment.map,
                            featureFlag: 'wayste-standard',
                        },
                        {
                            text: 'Inventory',
                            path: routes.equipment.inventory,
                            featureFlag: 'wayste-standard',
                        },
                        {
                            text: 'Tracking',
                            path: routes.driverTracking.list,
                            featureFlag: 'wayste-timetracking',
                        },
                        {
                            text: 'Vehicles',
                            path: routes.equipment.vehicles,
                            featureFlag: 'wayste-standard',
                            // featureFlag: 'wayste-commercial', //TODO: Change to wayste-standard
                        },
                    ]}
                />

                <ExpandingBlock
                    initiallyExpanded={true}
                    onNavigate={navigateSideEffect}
                    featureFlags={featureFlags}
                    heading="Back Office"
                    routeList={[
                        {
                            text: 'Billing',
                            path: routes.billing.list,
                        },
                        {
                            text: 'Pricing',
                            path: routes.pricing.list,
                        },
                        {
                            text: 'Time Sheets',
                            path: routes.timeSheet.list,
                            featureFlag: 'wayste-timetracking',
                        },
                        {
                            text: 'Reports',
                            path: routes.reports.list,
                            featureFlag: 'wayste-standard',
                        },
                        {
                            text: 'Customers',
                            path: routes.customers.list,
                            featureFlag: 'wayste-standard',
                        },
                    ]}
                />

                {hauler.isSourgumPartner && (
                    <ExpandingBlock
                        initiallyExpanded={false}
                        onNavigate={navigateSideEffect}
                        featureFlags={featureFlags}
                        heading="Partner Connect"
                        routeList={[
                            {
                                text: 'Partner Settings',
                                path: routes.partnerPortalConnect.list,
                                featureFlag: 'sourgum-marketplace-partner',
                            },
                            {
                                text: 'Partner RFPs',
                                path: routes.requestForProposal.list,
                                featureFlag: 'sourgum-marketplace-partner',
                            },
                        ]}
                    />
                )}
            </div>
        </nav>
    );
};

export default Toolbar;
